<template>
  <section class="section">
    <div class="error">
      <div>404</div>
      <div class="small">你所访问的页面不存在或者没有权限</div>
      <div class="info" @click="clg">点击此处前往首页</div>
    </div>
  </section>
</template>

<script>
export default {
  name: '404',
  methods: {
    clg() {
      this.$router.push('/choose');
    },
  },
};
</script>

<style scoped lang="scss">
.section {
  width: 100vw;
  height: 100vh;
  position: relative;
  .error {
    font-size: 100px;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, 0);
    color: #2b90fe;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .small {
      font-size: 50px;
    }
    .info {
      padding-top: 100px;
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      border-bottom: 1px solid #2b90fe;
    }
    .info:hover {
      opacity: 0.7;
    }
  }
}
</style>
